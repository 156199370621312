<template>
  <PageContent>
    <SubHeading>Log in to access ADMIN section</SubHeading>
    <div class="container">
      <div class="form" v-if="!inProgress">
        <div class="row">
          <label for="userName" v-if="false">UserName:</label>
          <input type="text" v-model="userName" name="userName" placeholder="Username"/>
        </div>
        <div class="row">
          <label for="password" v-if="false">Password:</label>
          <input type="password" v-model="password" name="password" placeholder="Password"/>
        </div>
        <button @click="login">LOG IN</button>
        <div class="errorMessage" v-if="inError">Either you UserName or Password was incorrect.</div>
      </div>
    </div>

    <Spinner v-if="inProgress"/>
  </PageContent>
</template>

<style scoped>
.container{
  display: flex;
  justify-content: center;
}
.form {
  background-color: rgba(0,0,0,0.04);
  border-radius: 20px;

  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);

  display: flex;
  flex-direction: column;
  width: 400px;
  margin-top: 10px;
}
label {
  width: 100px;
  display: inline-block;
}
.row {
  display: flex;
  margin-bottom: 10px;
}
.row input{
  flex: 1;
}
.errorMessage {
  margin-top: 20px;
  color: red;
}
button {
  background-color: lightgreen;
  border: 1px solid #ccc;
  padding: 5px;
  margin-top: 5px;
  font-size: 1.5em;
  color: #080;
  border-radius: 5px;
}
input {
  color: #080;
  font-size: 1.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 10px;
}
::placeholder {
  opacity: 0.2;
}
</style>

<script>
import {mapActions, mapGetters} from 'vuex';

import PageContent from '@/components/PageContent.vue'
import Spinner from '@/components/Spinner.vue'
import SubHeading from '@/components/SubHeading.vue'

export default {
  name: "Login",
  data() {
    return {
      userName: "",
      password: "",
      inProgress: false,
      inError: false
    }
  },
  components: {
    PageContent, Spinner, SubHeading
  },
  methods: {
    async login() {
      this.inProgress = true;
      try {
        await this.logIn({userName: this.userName, password: this.password});
        this.$router.push('admin/admin');
      }
      catch {
        this.inError = true;
      }
      finally {
        this.inProgress = false;
      }
    },
    ...mapActions(['logIn']),
    ...mapGetters(['token'])
  }
}
</script>